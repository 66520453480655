const state = {
  viewAreaHeight: window.innerHeight + 'px',
  viewAreaWidth: window.innerWidth + 'px',
  viewHeight: window.innerHeight,
  viewWidth: window.innerWidth,
  isShowAppNav: true,
  isEnglish: JSON.parse(sessionStorage.getItem('aoron_ow2_language_is_english')),
}

const mutations = {
  setViewAreaHeight(state, value) {
    state.viewAreaHeight = value + 'px'
    state.viewHeight = value
  },
  setViewAreaWidth(state, value) {
    state.viewAreaWidth = value + 'px'
    state.viewWidth = value
  },
  setIsShowAppNav(state, value) {
    state.isShowAppNav = value
  },
  setIsEnglish(state, value) {
    state.isEnglish = value
    sessionStorage.setItem('aoron_ow2_language_is_english', value)
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
