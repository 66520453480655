// 产品中心
const product = [
  {
    path: '/product',
    name: 'product',
    redirect: '/product/elecricHeater',
    component: () => import('@/views/Product/index'),
    children: [
      {
        path: 'waterHeater',
        component: () => import('@/views/Product/productSeries'),
      },
      {
        path: 'elecricHeater',
        component: () => import('@/views/Product/productSeries'),
      },
      {
        path: 'kitchenAid',
        component: () => import('@/views/Product/productSeries'),
      },
      {
        path: 'waterPurifier',
        component: () => import('@/views/Product/productSeries'),
      },
      {
        path: 'filterCartridge',
        component: () => import('@/views/Product/productSeries'),
      },
    ],
  },
  // 产品详情
  {
    path: '/product/productDetail/:id',
    name: 'product-detail',
    component: () => import('@/views/Product/productDetail'),
  },
]
export default product
