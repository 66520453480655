import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils'
import about from './modules/about'
import product from './modules/product'
import solution from './modules/solution'
import news from './modules/news'
import contactUs from './modules/contactUs'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: () => import('@/views/Layout/index'),
      redirect: '/home',
      children: [
        // 首页
        {
          path: 'home',
          component: () => import('@/views/Home/index'),
        },
        {
          path: 'statement/:id',
          name: 'statement',
          component: () => import('@/views/statement'),
        },
        about,
        ...product,
        ...solution,
        ...news,
        ...contactUs,
      ],
    },
    {
      path: '*',
      component: () => import('@/views/404'),
    },
  ],

  // 页面跳转后返回顶部
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})
// 导航守卫
const hasToken = getToken()
router.beforeEach((to, from, next) => {
  // 没有产品id，不跳转产品详情页，返回产品主页
  if (to.fullPath.includes('productDetail')) {
    if (!to.fullPath.split('/product/productDetail/')[1]) next('/product')
  }
  // 没有新闻资讯id,返回新闻中心
  if (to.fullPath.includes('newsDetail')) {
    if (!to.fullPath.split('/news/newsDetail/')[1]) next('/news')
  }
  if (to.fullPath === '/admin') {
    console.log(!hasToken)
    if (!hasToken) next('/login')
  }
  next()
})

export default router
