// 解决方案
const solution = [
  {
    path: '/solution',
    name: 'solution',
    component: () => import('@/views/Solution'),
    redirect: '/solution/RDCenter',
    children: [
      // 研发中心
      {
        path: 'RDCenter',
        name: 'RDCenter',
        component: () => import('@/views/Solution/RDCenter'),
      },
      // 生产中心
      {
        path: 'productionCenter',
        name: 'productionCenter',
        component: () => import('@/views/Solution/productionCenter'),
      },
      // 合作伙伴
      {
        path: 'cooperationPartners',
        name: 'cooperationPartners',
        component: () => import('@/views/Solution/cooperationPartners'),
      },
    ],
  },
]
export default solution
